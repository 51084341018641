import isShopInPathname from './isShopInPathname';

export const PIXELS = {
  gtm: 'gtm',
  klaviyo: 'klaviyo',
  adroll: 'adroll',
};

/**
 * Is pixel installed on a site and allowed to fire pixel events.
 * Typically, wraps pixel code in shared site components
 * GATSBY_ALLOW_LIST_PIXELS is comma separated env list -> `gtm,klaviyo,adroll`
 *
 */
export function isPixelSupported(pixelName: string): boolean {
  if (!pixelName) return false;
  const allowList = `${process.env.GATSBY_ALLOW_LIST_PIXELS}`.toLowerCase().split(',');
  return allowList.includes(pixelName);
}

// passing in window.location if possible
interface Location {
  pathname?: string;
  search?: string;
}

export function pixelCategoryFromLocation(location?: Location): string {
  if (!location) return '';

  return isShopInPathname(location?.pathname)
    ? location.pathname // collection page
    : new URLSearchParams(location.search).get(
        // pdp page
        'collection-location'
      );
}
